import React from "react";
import Banner from "../assets/images/about/back_bg_bottom.png";
import MobileBanner from "../assets/images/about/back_bottom_mobile.png";
import Image1 from "../assets/images/about/gallery_image_1.png";
import Image2 from "../assets/images/about/gallery_image_2.png";
import Image3 from "../assets/images/about/gallery_image_3.png";
import Image4 from "../assets/images/about/gallery_image_4.png";
import Image5 from "../assets/images/about/gallery_image_5.png";
import Image6 from "../assets/images/about/gallery_image_6.png";
import Image7 from "../assets/images/about/gallery_image_7.png";
import Image8 from "../assets/images/about/gallery_image_8.png";

const VisualLibrary = () => {
  return (
    <section className="relative flex px-4 md:px-8 lg:px-20 xl:px-36 w-full h-full min-h-screen overflow-hidden -mt-20 -mb-20">
      <img
        src={Banner}
        alt="Banner"
        className="absolute w-full h-full top-0 left-0 -z-30 hidden lg:flex"
      />
      <img
        src={MobileBanner}
        alt="Banner"
        className="absolute w-full h-full top-0 left-0 -z-30 flex lg:hidden"
      />
      <div className="flex flex-col w-full h-full py-40 gap-8">
        <h2 className="font-title text-yellow text-4xl lg:text-6xl text-center">
          Our Visual Library
        </h2>
        <div className="hidden md:flex flex-col gap-2 2xl:gap-4 w-full h-full">
          <div className="flex gap-2 2xl:gap-4 w-full h-full">
            <div className="flex flex-col gap-2 2xl:gap-4 w-1/3">
              <img src={Image1} alt="" className="h-3/4" />
              <img src={Image4} alt="" className="h-1/4" />
            </div>
            <div className="flex flex-col gap-2 2xl:gap-4 flex-1 w-1/3">
              <img src={Image2} alt="" className="h-1/2" />
              <img src={Image5} alt="" className="h-1/2" />
            </div>
            <img src={Image3} alt="" className="w-1/3 h-full" />
          </div>
          <div className="flex gap-4 2xl:gap-8 w-full h-full">
            <div className="flex flex-1 w-2/3 gap-2 2xl:gap-4">
              <img src={Image6} alt="" className="w-1/3" />
              <img src={Image7} alt="" className="w-2/3" />
            </div>
            <img src={Image8} alt="" className="w-1/3" />
          </div>
        </div>
        <div className="flex md:hidden flex-col gap-2 2xl:gap-4 w-full h-full">
          <div className="flex flex-col gap-2 w-full h-full">
            <div className="flex gap-2 w-full h-full">
              <div className="flex flex-col gap-2 w-1/2">
                <img src={Image1} alt="" className="h-3/4" />
                <img src={Image4} alt="" className="h-1/4" />
              </div>
              <div className="flex flex-col gap-2 w-1/2">
                <img src={Image2} alt="" className="h-1/2" />
                <img src={Image5} alt="" className="h-1/2" />
              </div>
            </div>
            <div className="flex gap-2 w-full h-full">
              <div className="flex flex-col gap-2 w-1/2">
                <img src={Image8} alt="" className="h-1/2" />
                <img src={Image6} alt="" className="h-1/2" />
              </div>
              <div className="flex w-1/2">
                <img src={Image3} alt="" className="h-full" />
              </div>
            </div>
            <div className="flex w-full">
              <img src={Image7} alt="" className="w-full" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VisualLibrary;
