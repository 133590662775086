import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TestimonialCard from "../components/TestimonialCard";
import LeftArrow from "../assets/images/franchise/arrow_left.png";
import RightArrow from "../assets/images/franchise/arrow_right.png";
import Banner from "../assets/images/franchise/franchise_bg_bottom.png";

const CustomRightArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  // onMove means if dragging or swiping in progress.
  return (
    <button onClick={() => onClick()} className="absolute right-0">
      <img
        src={RightArrow}
        alt="Left Arrow"
        className="w-6 md:w-10 xl:w-12 object-contain"
      />
    </button>
  );
};
const CustomLeftArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  // onMove means if dragging or swiping in progress.
  return (
    <button onClick={() => onClick()} className="absolute left-0">
      <img
        src={LeftArrow}
        alt="Right Arrow"
        className="w-6 md:w-10 xl:w-12 object-contain"
      />
    </button>
  );
};

const Testimonial = () => {
  const data = [
    {
      name: "Rahul Padwani",
      desc: "I have tried infinite numbers of pizzas from Italy, Canada, and especially street-style pizzas of India. This time I had the wonderful experience of having delicious pizzas from The Fusion Pizza, Gotri, Vadodara.",
      occu: "Food logger",
      social_id: "the_rahulpadwani",
    },
    {
      name: "Samiksha Yadav",
      desc: "The Fusion Pizza has come up with amazing offers which just can't be missed!",
      occu: "Food logger",
      social_id: "_midnight.cravings",
    },
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <section className="relative flex flex-col w-full h-full px-4 md:px-8 lg:px-20 xl:px-36 py-24 -mt-24">
      <img
        src={Banner}
        alt="Background Image"
        className="absolute w-full h-full top-0 left-0 -z-10 flex object-cover"
      />
      <Carousel
        className="relative pt-24 lg:pt-12 py-8 lg:py-12 px-4 md:px-24 lg:px-26 xl:px-14"
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        containerClass="carousel-container"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={true}
        renderDotsOutside={false}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
        responsive={responsive}
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
      >
        {data.map((review, id) => {
          return (
            <div key={id}>
              <TestimonialCard review={review} id={id} />
            </div>
          );
        })}
      </Carousel>
    </section>
  );
};

export default Testimonial;
