import React, { useEffect } from "react";
import ContactUsContainer from "../contactContainers/ContactUsContainer";
import ContactBanner from "../contactContainers/ContactBanner";
import Map from "../contactContainers/Map";
import { useLocation } from "react-router-dom";

const Contact = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <ContactBanner />
      <ContactUsContainer />
      <Map
        center={{ lat: 23.049875390611483, lng: 72.67243189999999 }}
        zoom={17}
      />
    </>
  );
};

export default Contact;
