import React from "react";
import Banner from "../assets/images/contact/contact_bg1.png";

const ContactBanner = () => {
  return (
    <section className="relative flex z-10 md:mt-8">
      <img
        src={Banner}
        alt="Contact Banner"
        className="object-contain w-full"
      />
    </section>
  );
};

export default ContactBanner;
