import React from "react";
import Banner from "../assets/images/franchise/franchise_bg_Top.png";
import BannerMobile from "../assets/images/franchise/back_top_mobile.png";
import Icon1 from "../assets/images/franchise/icon_1.png";
import Icon2 from "../assets/images/franchise/icon_2.png";
import Icon3 from "../assets/images/franchise/icon_3.png";
import Icon4 from "../assets/images/franchise/icon_4.png";
import YellowLine from "../assets/images/franchise/yellowLine.png";
import Element from "../assets/images/franchise/element_1.png";

const FranchiseDetails = () => {
  return (
    <section className="relative flex px-4 md:px-8 lg:px-20 xl:px-36 -mt-2">
      <img
        src={Banner}
        alt="Background Image"
        className="absolute w-full h-full top-0 left-0 -z-10 hidden lg:flex"
      />
      <img
        src={BannerMobile}
        alt="Background Image"
        className="absolute w-full h-full top-0 left-0 -z-10 flex lg:hidden object-left-top object-cover"
      />
      <img
        src={Element}
        alt="Element"
        className="absolute -top-4 md:-top-8 lg:top-auto bottom-auto lg:bottom-40 object-contain right-0 w-24 md:w-32 lg:w-auto -z-10"
      />
      <div className="flex flex-col justify-center items-center w-full pt-32 md:pt-24 gap-4 pb-60">
        <p className="uppercase font-description-semibold text-white text-lg md:text-2xl xl:text-3xl max-w-xs md:max-w-lg lg:max-w-3xl text-center">
          ARE YOU PASSIONATE ABOUT FOOD AND LOOKING TO BE YOUR OWN BOSS?
        </p>
        <span className="font-description-light text-white text-lg xl:text-xl -mt-4">
          You've landed at the right place!
        </span>
        <img
          src={YellowLine}
          alt="Yellow Line"
          className="object-contain my-2"
        />
        <h1 className="text-yellow font-title text-2xl md:text-3xl xl:text-4xl text-center max-w-lg lg:max-w-2xl xl:max-w-4xl tracking-wide leading-snug">
          We're inviting you to be a Franchise Owner of India's first
          quick-service pizza restaurant!
        </h1>
        <p className="text-white font-description-bold text-lg text-center lg:text-sm pb-8 max-w-xs lg:max-w-auto">
          SMALL AREA- 400 SQ. FT. | MEDIUM AREA- 600 SQ. FT. | PREMIUM AREA-
          1000 SQ. FT.
        </p>
        <div className="flex gap-2 md:gap-8 lg:gap-16 justify-center items-center text-center">
          <div className="flex flex-col gap-4 justify-center items-center">
            <img
              src={Icon1}
              alt="Icon 1"
              className="object-contain w-8 md:w-auto"
            />
            <p className="text-yellow font-title text-2xl md:text-4xl xl:text-5xl">
              12
            </p>
            <p className="text-white uppercase text-xs md:text-lg xl:text-2xl">
              OUTLETS PAN <br /> INDIA
            </p>
          </div>
          <div className="flex flex-col gap-4 justify-center items-center">
            <img
              src={Icon2}
              alt="Icon 2"
              className="object-contain w-8 md:w-auto"
            />
            <p className="text-yellow font-title text-2xl md:text-4xl xl:text-5xl">
              500K+
            </p>
            <p className="text-white uppercase text-xs md:text-lg xl:text-2xl">
              HAPPY <br /> CUSTOMERS
            </p>
          </div>
          <div className="flex flex-col gap-4 justify-center items-center">
            <img
              src={Icon3}
              alt="Icon 3"
              className="object-contain w-8 md:w-auto"
            />
            <p className="text-yellow font-title text-2xl md:text-4xl xl:text-5xl">
              300K+
            </p>
            <p className="text-white uppercase text-xs md:text-lg xl:text-2xl">
              DELIVERY <br /> ORDERS
            </p>
          </div>
          <div className="flex flex-col gap-4 justify-center items-center">
            <img
              src={Icon4}
              alt="Icon 4"
              className="object-contain w-8 md:w-auto"
            />
            <p className="text-yellow font-title text-2xl md:text-4xl xl:text-5xl">
              100%
            </p>
            <p className="text-white uppercase text-xs md:text-lg xl:text-2xl">
              PROFITABLE <br /> OUTLETS
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FranchiseDetails;
