import React, { useEffect } from "react";
import FranchiseBanner from "../franchiseContainers/FranchiseBanner";
import FranchiseDetails from "../franchiseContainers/FranchiseDetails";
import InquiryAndServices from "../franchiseContainers/InquiryAndServices";
import Testimonial from "../franchiseContainers/Testimonial";
import { useLocation } from "react-router-dom";

const Franchise = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <FranchiseBanner />
      <FranchiseDetails />
      <InquiryAndServices />
      <Testimonial />
    </>
  );
};

export default Franchise;
