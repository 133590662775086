import React, { useEffect } from "react";
import AboutBanner from "../aboutContainers/AboutBanner";
import AboutFounders from "../aboutContainers/AboutFounders";
import VisualLibrary from "../aboutContainers/VisualLibrary";
import { useLocation } from "react-router-dom";

const About = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <AboutBanner />
      <AboutFounders />
      <VisualLibrary />
    </>
  );
};

export default About;
