import React from "react";
import MobileBanner from "../assets/images/home/homeMobile.png";
import Banner from "../assets/images/home/banner.png";
import SmallPizza from "../assets/images/home/Icon_1.png";
import HomeTopBG from "../assets/images/home/back_bg_top.png";
import HomeTopMobile from "../assets/images/home/section2_bg.png";
import HomeTopMobileElement from "../assets/images/home/HomeTopElement.png";
import Tomato from "../assets/images/home/Tomato_1.png";
import Leaf from "../assets/images/home/leaf_1.png";

const HomeBanner = () => {
  return (
    <section className="flex flex-col h-full">
      <img
        src={Banner}
        alt="Banner"
        className="w-full object-contain hidden md:flex"
      />
      <img
        src={MobileBanner}
        alt="Banner"
        className="w-full object-contain flex md:hidden"
      />
      <div className="relative flex flex-col text-center w-full h-full justify-center items-center px-4 md:px-8 lg:px-20 xl:px-36 pb-16 pt-40 -mt-12 lg:-mt-28">
        <img
          src={HomeTopBG}
          alt="Background Image"
          className="absolute w-full h-full top-0 left-0 -z-10 hidden lg:flex"
        />
        <img
          src={HomeTopMobile}
          alt="Background Image"
          className="absolute w-full h-full top-0 left-0 -z-10 flex lg:hidden"
        />
        <img
          src={HomeTopMobileElement}
          alt="Background Image"
          className="absolute object-contain w-28 top-10 left-0 -z-10 flex lg:hidden"
        />
        <img
          src={SmallPizza}
          alt="Pizza icon"
          className="object-contain w-32 md:w-48 mb-4"
        />
        <img
          src={Tomato}
          alt="Pizza icon"
          className="absolute object-contain mt-8 lg:mt-0 w-12 md:w-20 xl:w-32 left-4 md:left-12 xl:left-8 2xl:left-40 bottom-auto lg:bottom-0 xl:bottom-4"
        />
        <img
          src={Leaf}
          alt="Pizza icon"
          className="absolute object-contain w-16 md:w-24 xl:w-32 right-8 lg:right-16 2xl:right-36 top-40 lg:top-auto"
        />
        <p className="text-white font-description-medium xl:max-w-6xl text-base md:text-lg mb-8 max-w-xs md:max-w-none">
          Pizza is the heartthrob of fast food in India. But let's face it; the
          international big shots were hogging the spotlight, leaving us craving
          something more-something uniquely Indian, outrageously delicious, and
          damn affordable.
        </p>
        <p className="text-yellow font-description-mediumitalic xl:max-w-6xl text-base md:text-lg max-w-64 md:max-w-none">
          That's when The Fusion Pizza charged into the scene!
        </p>
        <p className="text-white font-description-medium xl:max-w-6xl text-base md:text-lg max-w-xs md:max-w-none">
          Started with 2 exceptional & another additional partner been brought
          on board who is responsible for overseeing and managing the brand's
          development, ensuring a collobrative efforts towards achieving the
          good and a strong foundation for the brand's future growth.
          {/* entrepreneurs who shared the same
          profession and a fervent passion for food, the primary objective was
          to fill the void in the Indian market. With the increasing demand for
          pizza and the absence of an Indian pizza brand that sells the best
          quality at an economical price, The Fusion Pizza emerged to bridge the
          gap. */}
        </p>
      </div>
    </section>
  );
};

export default HomeBanner;
