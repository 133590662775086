import React from "react";
import Banner from "../assets/images/about/back_bg_top.png";
import MobileBanner from "../assets/images/about/about_us_mobile.png";

const AboutBanner = () => {
  return (
    <section className="relative flex h-full">
      <img
        src={Banner}
        alt="Banner"
        className="absolute w-full h-full top-0 left-0 -z-10 hidden lg:flex"
      />
      <img
        src={MobileBanner}
        alt="Banner"
        className="absolute w-full h-full top-0 md:top-16 left-0 -z-10 flex lg:hidden"
      />
      <div className="flex px-4 md:px-8 lg:px-20 xl:px-36 justify-center items-center w-full h-full flex-col gap-4 lg:pt-48 pb-20">
        {/* <p className="text-yellow uppercase tracking-wide xl:tracking-wider font-description-light text-lg md:text-2xl xl:text-3xl pt-20 md:pt-24 lg:pt-0 text-center">
          A Success Story Spanning over
        </p>
        <h1 className="text-yellow font-title text-4xl md:text-5xl lg:text-7xl">
          12+ YEARS,
        </h1>
        <h2 className="text-white font-title text-3xl md:text-4xl lg:text-5xl pb-72 md:pb-48 lg:pb-0">
          AND COUNTING!
        </h2> */}
        <div className="flex flex-col md:flex-row gap-8 md:gap-4 xl:gap-12 pt-96 lg:pt-12 max-w-2xl xl:max-w-3xl">
          <div className="flex flex-col gap-2 md:gap-4 items-center justify-center text-center md:text-left md:items-start">
            <span className="text-white uppercase tracking-wide font-description-bold text-2xl">
              Vision
            </span>
            <p className="text-white">
              To be the world's most beloved QSR abrand, recognized for
              exceptional pizzas and empowered by a passionate team and thriving
              franchise network.
            </p>
          </div>
          <div className="flex flex-col gap-2 md:gap-4 items-center justify-center text-center md:text-left md:items-start">
            <span className="text-white uppercase tracking-wide font-description-bold text-2xl">
              Mission
            </span>
            <p className="text-white">
              To craft and deliver the highest quality pizzas, consistently
              exceeding expectations with speed, convenience, and a welcoming
              environment
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutBanner;
