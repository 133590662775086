import React, { useCallback, useEffect } from "react";
import HomeBottomBG from "../assets/images/home/back_bg_bottom.png";
import Element1 from "../assets/images/home/icon_1_About_us.png";
import Element2 from "../assets/images/home/icon_2_About_us.png";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import useRestaurantCategory from "../data/talons/RestaurantCategory/useRestaurantCategory";
import { useDispatch } from "react-redux";
import { resetInquiryFormData } from "../data/reducers/restaurantCategory.reducer";

const Contact = () => {
  const dispatch = useDispatch();
  const {
    InquiryEntryHome,
    InquiryEntry,
    formLoadEvent,
    setAlert,
    inquiryEntrySchema,
    alert,
  } = useRestaurantCategory();

  useEffect(() => {
    formLoadEvent();
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
  } = useForm({
    resolver: joiResolver(inquiryEntrySchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      contact_num: "",
      email_id: "",
      city: "",
    },
  });

  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");

    if (e.target.value.toString().length === 0) return;
    return (e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 10));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlert(false);
    }, 3000);

    // To clear or cancel a timer, you call the clearTimeout(); method,
    // passing in the timer object that you created into clearTimeout().

    return () => clearTimeout(timer);
  }, [alert]);

  const onUserSubmit = useCallback(async () => {
    try {
      const res = await InquiryEntryHome({
        ...getValues(),
      });
      if (res == true) {
        setAlert(true);
        reset();
        dispatch(resetInquiryFormData());
        setValue("");
      }
      console.log("Form ::: ", res);
    } catch (err) {
      console.log("errr", err);
    }
  }, [InquiryEntry]);

  return (
    <section className="relative flex flex-col justify-center items-center">
      <img
        src={HomeBottomBG}
        alt="Background Image"
        className="absolute w-full h-full -z-10 object-cover"
      />
      <img
        src={Element1}
        alt="Background Image"
        className="absolute  w-1/4 md:w-32 object-contain bottom-8 left-0 -z-10 flex lg:hidden"
      />
      <img
        src={Element2}
        alt="Background Image"
        className="absolute w-1/5 md:w-32 object-contain top-0 right-0 -z-10 flex lg:hidden"
      />
      <form className="flex flex-col gap-8 justify-center items-center py-12 xl:py-24">
        <h3 className="font-title text-yellow text-4xl md:text-5xl text-center">
          GET IN TOUCH
        </h3>
        <div className="flex gap-8 w-full flex-wrap justify-center">
          <input
            type="text"
            placeholder="First Name"
            {...register("first_name")}
            autoComplete="false"
            className="flex bg-transparent border-white border-2 rounded-lg py-2 px-4 text-white min-w-72"
          />
          <input
            type="text"
            placeholder="Last Name"
            {...register("last_name")}
            autoComplete="false"
            className="flex bg-transparent border-white border-2 rounded-lg py-2 px-4 text-white min-w-72"
          />
        </div>
        <div className="flex gap-8 w-full flex-wrap justify-center">
          <input
            type="email"
            placeholder="Your E-mail ID"
            {...register("email_id")}
            autoComplete="false"
            className="flex bg-transparent border-white border-2 rounded-lg py-2 px-4 text-white min-w-72"
          />
          <input
            type="tel"
            onInput={(e) => onlyNumbers(e)}
            placeholder="Mobile Number"
            {...register("contact_num")}
            autoComplete="false"
            className="flex bg-transparent border-white border-2 rounded-lg py-2 px-4 text-white min-w-72"
          />
        </div>
        <button
          className="bg-yellow text-green uppercase font-description-bold tracking-wide font-3xl py-2 px-6 rounded-lg"
          onClick={(e) => {
            e.preventDefault();
            handleSubmit(onUserSubmit());
          }}
        >
          Submit Form
        </button>
        {alert === true ? (
          <div className="text-center ease-in text-white">
            Thank You For Showing Interest!
          </div>
        ) : null}
      </form>
    </section>
  );
};

export default Contact;
