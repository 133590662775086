import React from "react";
import Banner from "../assets/images/about/back_bg_mid.png";
import MobileBanner from "../assets/images/about/back_mid_mobile.png";
import VimalPatel from "../assets/images/about/Vimal_patel.png";
import BrijeshChauhan from "../assets/images/about/brijesh_chauhan.png";
import VijethJain from "../assets/images/about/vijeth_jain.png";
import CurveLines from "../assets/images/home/CurveLines.png";

const AboutFounders = () => {
  return (
    <section className="relative flex px-4 md:px-8 lg:px-20 xl:px-36 w-full h-full -mt-12 md:-mt-8 lg:-mt-20 pb-32 pt-24 md:pt-40">
      <img
        src={Banner}
        alt="Banner"
        className="absolute w-full h-full top-0 left-0 -z-20 hidden lg:flex"
      />
      <img
        src={MobileBanner}
        alt="Banner"
        className="absolute w-full h-full top-0 left-0 -z-20 flex lg:hidden"
      />
      <div className="flex flex-col justify-center items-center ">
        <p className="font-title text-dark-green text-2xl md:text-4xl lg:text-5xl xl:text-6xl pb-2 text-center">
          The Visionary Leaders of
        </p>
        <p className="font-title text-yellow text-2xl md:text-4xl lg:text-5xl xl:text-6xl text-center">
          The Fusion Pizza
        </p>
        <div className="flex flex-col xl:flex-row gap-12 lg:gap-24 xl:gap-12 pt-16">
          <div className="flex flex-col md:flex-row xl:flex-col gap-4 md:gap-8 flex-1 text-center lg:text-left items-center">
            <div className="relative flex-1 flex w-full justify-center lg:justify-start">
              <img
                src={VimalPatel}
                alt="Vimal Patel"
                className="object-contain flex-1 pr-24"
              />
              <div className="absolute right-8 md:-right-4 bottom-24 md:bottom-16 lg:bottom-auto lg:top-60 xl:top-44 flex flex-col items-center lg:items-start">
                <span className="font-description-bold text-white bg-dark-green py-1 xl:py-2 px-2 xl:px-4 text-sm md:text-lg xl:text-lg">
                  MR. VIMAL PATEL
                </span>
                <span className="p-1 bg-yellow text-green uppercase text-sm md:text-lg xl:text-lg">
                  - FOUNDER DIRECTOR
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-4 md:gap-8 flex-1 text-center pt-8 md:pt-0 lg:text-left">
              <p className="text-green text-sm md:text-base font-bold">
                Driven by a love for food and a passion for innovation, Mr.
                Vimal Patel, a seasoned entrepreneur with over a decade of
                experience in the hospitality industry, conceived the idea for
                The Fusion Pizza.
              </p>
              <p className="text-green text-sm md:text-base font-bold">
                His journey began with a Bachelor's degree in Hotel and Tourism
                Management, which propelled him into the heart of the industry.
                He honed his skills at renowned organizations like Bollants IM
                Park Hotel in Germany, Skylon Hotel, and Jubilant Foodworks, the
                operator of Domino's Pizza.
              </p>
              <p className="text-green text-sm md:text-base font-bold">
                This international exposure and diverse experience fueled his
                entrepreneurial spirit, culminating in the birth of The Fusion
                Pizza.
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row-reverse xl:flex-col gap-4 md:gap-8 flex-1 text-center lg:text-left items-center">
            <div className="relative flex flex-1 w-full justify-center lg:justify-end">
              <img
                src={BrijeshChauhan}
                alt="Brijesh Chauhan"
                className="object-contain flex-1 px-12"
              />
              <div className="absolute left-auto lg:left-[20%] -bottom-4 lg:-bottom-12 flex flex-col items-center">
                <span className="font-description-bold text-white bg-dark-green py-1 xl:py-2 px-2 xl:px-4 text-sm md:text-lg xl:text-lg">
                  MR. BRIJESH CHAUHAN
                </span>
                <span className="p-1 bg-yellow text-green uppercase text-sm md:text-lg xl:text-lg">
                  - DIRECTOR
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-4 md:gap-8 flex-1 text-center lg:text-left">
              <p className="text-green text-sm md:text-base pt-10 font-bold">
                Mr. Brijesh Chauhan, a seasoned FMCG veteran with over 15 years
                of experience, brings a wealth of market knowledge and sales
                expertise to The Fusion Pizza. He has honed his skills at
                leading companies like Rasna, Adf food, Campco, and Desai
                Brothers, where he developed a keen understanding of consumer
                needs and preferences.
              </p>
              <p className="text-green text-sm md:text-base font-bold">
                Driven by a passion for healthy and delicious food, Mr. Chauhan
                is a key innovator at The Fusion Pizza. He reimagines
                traditional recipes, creating a unique range of quick-service
                offerings that are both flavorful and nutritious. As a
                co-founder, he plays a pivotal role in driving the company's
                marketing and sales efforts, ensuring that The Fusion Pizza
                reaches a wider audience.
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row xl:flex-col gap-4 md:gap-8 flex-1 text-center lg:text-left items-center">
            <div className="relative flex-1 flex w-full justify-center md:justify-start xl:justify-start ">
              <img
                src={VijethJain}
                alt="Vimal Patel"
                className="object-contain flex-1 pr-24 xl:pr-0 xl:pl-24"
              />
              <div className="absolute right-0 xl:right-auto left-auto xl:-left-10 bottom-24 xl:top-44 flex flex-col items-center lg:items-start xl:items-end">
                <span className="font-description-bold text-white bg-dark-green py-1 xl:py-2 px-2 xl:px-4 text-sm md:text-lg xl:text-lg">
                  MR. VIJETH JAIN
                </span>
                <span className="p-1 bg-yellow text-green uppercase text-sm md:text-lg xl:text-lg">
                  - DIRECTOR
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-4 md:gap-8 flex-1 text-center lg:text-left">
              <p className="text-green text-sm md:text-base font-bold">
                Vijeth Jain, with an MBA in Finance and Marketing, has
                accumulated over 15 years of expertise in banking and
                franchising. As a seasoned professional, he has now taken on the
                role of a director to expand the company's reach.
              </p>
              <p className="text-green text-sm md:text-base font-bold">
                His vision involves not only establishing the brand in the
                pan-India market but also exploring opportunities in the
                international arena. This strategic move aims to maximize the
                organization's growth and success on a global scale.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="flex flex-col pt-12 lg:pt-20 gap-2 md:gap-4 justify-center items-center w-64 md:w-auto">
          <img src={CurveLines} alt="Yellow Curve" />
          <p className="font-description-light text-dark-green text-base md:text-2xl lg:text-3xl xl:text-4xl text-center">
            FOR FRANCHISE INQUIRY, CALL{" "}
            <span className="font-description-bold">+91 99048 84433</span>
          </p>
          <img src={CurveLines} alt="Yellow Curve" />
        </div> */}
      </div>
    </section>
  );
};

export default AboutFounders;
