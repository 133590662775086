import React from "react";
import GoogleMapReact from "google-map-react";
import ContactLocation from "../assets/images/contact/Location.png";

const AnyReactComponent = () => (
  <img
    src={ContactLocation}
    alt="Location"
    style={{ width: 150, objectFit: "contain" }}
  />
);

export default function Map(props) {
  const { center, zoom } = props;

  return (
    <div style={{ height: "50vh", width: "100%" }}>
      {/* <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAF6YsJOwih_sVZfcIYeXzEDoHce60bUno" }}
        defaultCenter={center}
        defaultZoom={zoom}
      >
        <AnyReactComponent lat={center.lat} lng={center.lng} />
      </GoogleMapReact> */}
      <iframe
        src="https://www.google.com/maps/d/u/0/embed?mid=1KP-vAjH7h3Tknc8FyEih6FI-8vrOeXk&ehbc=2E312F"
        width="100%"
        height="480"
        style={{ marginTop: "-65px" }}
      ></iframe>
    </div>
  );
}
