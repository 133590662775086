import React from "react";
import Review from "../assets/images/franchise/review_bg.png";
import "../index.css";

const TestimonialCard = (props) => {
  const { review } = props;
  return (
    <div className="flex relative">
      <img
        src={Review}
        alt="Testimonial Card"
        className="w-11/12 md:w-3/4 xl:w-10/12 object-contain"
      />
      <div className="absolute flex justify-start flex-col top-0 bottom-0 left-0 w-3/4 xl:w-10/12 px-4 md:px-12 pt-4 md:pt-8 gap-2 md:gap-8">
        <p className="line-clamp-2 md:line-clamp-4 text-sm md:text-base">
          {review.desc}
        </p>
        <div className="flex flex-col">
          <span className="font-description-bold text-green text-lg md:text-2xl">
            {review.name}
          </span>
          <span className="text-sm md:text-base">-{review.occu}</span>
          <span className="text-sm md:text-base">@{review.social_id}</span>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
