import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {
    restaurantCategory: [],
    restaurantProduct: [],
    branchMasterList: [],
    inquiryList: [],
    branchId: 0
  },
};

export const restaurantCategorySlice = createSlice({
  name: "restaurantCategoryReducer",
  initialState,
  reducers: {
    resetInquiryFormData: (state, action) => {
      return {
        ...initialState,
      };
    },
    setRestaurantCategoryList: (state, action) => {
      state.filters.restaurantCategory = action.payload;
    },
    setRestaurantProductList: (state, action) => {
      state.filters.restaurantProduct = action.payload;
    },
    setBranchMasterList: (state, action) => {
      state.filters.branchMasterList = action.payload;
    },
    setInquiryList: (state, action) => {
      state.filters.inquiryList = action.payload;
    },
    setBranchId: (state, action) => {
      state.filters.branchId = action.payload;
    },
  },
});

export const {
  resetInquiryFormData,
  setRestaurantCategoryList,
  setRestaurantProductList,
  setBranchMasterList,
  setInquiryList,
  setBranchId
} = restaurantCategorySlice.actions;

export default restaurantCategorySlice.reducer;
