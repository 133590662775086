import React from "react";
import FranchiseHouses from "../assets/images/home/franchiese_1.png";
import CurveLines from "../assets/images/home/CurveLines.png";
import Leaf from "../assets/images/home/leaf_3.png";

const Franchise = () => {
  return (
    <section className="relative flex w-full justify-center px-4 md:px-8 lg:px-20 xl:px-36 py-12 min-h-[70vh]">
      <img
        src={FranchiseHouses}
        alt="Franchises"
        className="absolute bottom-02 md:bottom-20 lg:-bottom-16 left-0 object-contain"
      />
      <img
        src={Leaf}
        alt="Leaf"
        className="absolute object-contain w-24 md:w-40 xl:w-48 -top-8 left-8 md:left-20 lg:left-1/4"
      />
      <div className="flex flex-col gap-4 justify-center items-center text-center">
        <h3 className="text-green font-title pb-10 text-4xl xl:text-5xl">
          FRANCHISE
        </h3>
        <p className="font-description-medium text-lg max-w-3xl xl:max-w-4xl">
          From a vision to nationwide reality-our brand is on the move! Before
          sharing our success with franchises ,we made a promise: every outlet
          mirrors the quality, taste, and experience we began with. This
          commitment has yielded slow but sure fruits. Our unmatched quality and
          taste have earned us a remarkable customer base-we never take that for
          granted. If you're ready to craft your success story with a brand
          where goodwill speaks louder than words,
        </p>
        {/* <p className="text-yellow font-description-semibolditalic text-xl uppercase">
          WE INVITE YOU TO BE A PART OF OUR THRIVING FRANCHISE FAMILY
        </p> */}
        {/* <img
          src={CurveLines}
          alt="Curve line"
          className="object-contain max-w-xs lg:max-w-4xl"
        />
        <p className="text-green font-description text-wrap text-base md:text-xl lg:text-2xl xl:text-3xl max-w-xs lg:max-w-4xl px-12 md:px-0">
          FOR FRANCHISE INQUIRY, CALL{" "}
          <span className="font-description-bold">+91 99048 84433</span>
        </p>
        <img
          src={CurveLines}
          alt="Curve line"
          className="object-contain max-w-xs lg:max-w-4xl"
        /> */}
        {/* <p className="font-description-bold text-lg lg:text-xl text-green max-w-xs lg:max-w-xl">
          (SMALL AREA- 400 SQ. FT. | MEDIUM AREA- 600 SQ. FT. | PREMIUM AREA-
          1000 SQ. FT.)
        </p> */}
      </div>
    </section>
  );
};

export default Franchise;
