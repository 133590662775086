import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Franchise from "./pages/Franchise";
import Contact from "./pages/Contact";
import SignUpForm from "./pages/WelcomeOffer";

function App() {
  // if(window.location.pathname === '/welcome-offer'){
  //   return <Home/>
  // }
  return (
    <BrowserRouter>
      <Navbar />
      <main className="h-full min-h-screen overflow-hidden">
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/franchise" element={<Franchise />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/welcome-offer" exact element={<Home />} />
        </Routes>
      </main>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
