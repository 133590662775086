import React, { useEffect } from "react";
import HomeBanner from "../homeContainers/HomeBanner";
import Verticals from "../homeContainers/Verticals";
import Menu from "../homeContainers/Menu";
import Franchise from "../homeContainers/Franchise";
import Contact from "../homeContainers/Contact";
import { useLocation } from "react-router-dom";

const Home = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <HomeBanner />
      <Verticals />
      <Menu />
      <Franchise />
      <Contact />
    </>
  );
};

export default Home;
