import React from "react";
import { get, size } from "lodash";

export default function Dropdown({
  title,
  data,
  handleDropdownChange,
  dropdownValue,
  onClick,
  classname,
}) {
  return (
    <select
      value={dropdownValue}
      title={title}
      onChange={handleDropdownChange}
      onClick={onClick}
      className={classname}
    >
      {!size(data) ? (
        <option key="select" value="select">
          Select
        </option>
      ) : (
        <>
          {data.map((item) => {
            return (
              <option key={item.id} value={get(item, "id", "no-data")}>
                {item.label}
              </option>
            );
          })}
        </>
      )}
    </select>
  );
}
