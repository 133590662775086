import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import NavLogo from "../assets/images/NavLogo.png";
import Hamburger from "../assets/images/hamburger.png";

const Navbar = () => {
  const location = useLocation();
  const [mobileMenu, setMobileMenu] = useState(false);
  const [stickyClass, setStickyClass] = useState(false);

  function stickNavbar() {
    let windowHeight = window.scrollY;
    windowHeight > 100 ? setStickyClass(true) : setStickyClass(false);
  }

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
  }, []);

  const routes = [
    {
      id: 1,
      link: "/",
      name: "Home",
    },
    {
      id: 2,
      link: "/about",
      name: "About Us",
    },
    {
      id: 3,
      link: "/",
      icon: { NavLogo },
      name: "Logo",
    },
    {
      id: 4,
      link: "/franchise",
      name: "Franchise",
    },
    {
      id: 5,
      link: "/contact",
      name: "Contact Us",
    },
  ];

  return (
    <nav
      className={`fixed z-50 flex w-full justify-center items-center ${
        stickyClass || location.pathname !== "/" ? "bg-white" : "bg-transparent"
      }`}
    >
      <ul
        className={`hidden md:flex justify-between items-start w-full max-w-7xl px-4 md:px-8 lg:px-20 xl:px-36 py-4 lg:py-6 uppercase `}
      >
        {routes.map((route) => {
          return (
            <li
              key={route.id}
              className="flex flex-col justify-center relative"
            >
              <NavLink
                to={route.link}
                onClick={() => setMobileMenu(!mobileMenu)}
                className={({ isActive, isPending }) =>
                  isPending
                    ? ""
                    : isActive && route.name !== "Home"
                    ? "font-description-semibold text-green text-sm lg:text-base"
                    : "hover:text-yellow flex flex-col whitespace-nowrap text-green font-description-semibold text-sm lg:text-base"
                }
              >
                {route.name === "Logo" ? (
                  <img
                    src={NavLogo}
                    alt="Logo"
                    className={`fixed top-0 left-[40%] xl:left-[43%] object-contain w-28 lg:w-44 ${
                      stickyClass || location.pathname !== "/"
                        ? "bg-white"
                        : "bg-transparent"
                    } py-4 lg:py-6 px-6 lg:px-10 rounded-3xl lg:rounded-b-[3rem]`}
                  />
                ) : (
                  route.name
                )}
              </NavLink>
              <span
                className={`w-4/5 h-0.5 bg-yellow my-0 rounded-full ${
                  location.pathname === route.link && route.name !== "Logo"
                    ? "flex"
                    : "hidden"
                }`}
              />
            </li>
          );
        })}
      </ul>
      <div className="relative flex md:hidden justify-center items-center w-full">
        <img
          src={Hamburger}
          alt="Hamburger"
          className="object-contain flex md:hidden w-10 fixed top-4 right-4"
          onClick={() => setMobileMenu(!mobileMenu)}
        />
        <img
          src={NavLogo}
          alt="Logo"
          className={`fixed object-contain w-32 bg-white top-0 px-8 py-2 rounded-b-3xl ${
            location.pathname !== "/" ? "flex" : "hidden"
          }`}
        />
        <ul
          className={`${
            mobileMenu ? "flex" : "hidden"
          } flex-col absolute top-16 bg-green p-4 gap-4 rounded-xl min-w-36 self-end right-4`}
        >
          {routes.map((route) => {
            return route.name !== "Logo" ? (
              <li
                key={route.id}
                className="flex flex-col justify-center relative "
              >
                <NavLink
                  to={route.link}
                  onClick={() => setMobileMenu(!mobileMenu)}
                  className={({ isActive, isPending }) =>
                    isPending
                      ? ""
                      : isActive && route.name !== "Home"
                      ? "font-description-semibold text-yellow"
                      : `hover:text-yellow flex flex-col whitespace-nowrap text-yellow font-description-semibold`
                  }
                >
                  {route.name}
                </NavLink>

                <span
                  className={`w-10 h-0.5 bg-yellow my-0 rounded-full ${
                    location.pathname === route.link && route.name !== "Logo"
                      ? "flex"
                      : "hidden"
                  }`}
                />
              </li>
            ) : (
              <li className="hidden" key={route.id}></li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
