import Axios, { AxiosRequestConfig } from "axios";
import CryptoJS from "crypto-js";
import { size } from "lodash";

// const axios = Axios.create({
//   //baseURL: "http://localhost:3000",
//   baseURL: "http://api.crmemperor.com/",
//   headers: {
//     Authorization: `Bearer ${localStorage.getItem("token")}`,
//   },
// });

const axios = Axios.create({
  //baseURL: "http://localhost:3000",
  //baseURL: "https://thefusionpizza.in/api/",
  baseURL: "https://api.crmemperor.com/",
});

const secret = "RJHRrdHIXnIujihrIIUlLiDheIhlaXli";

export default class Api {
  static get(config) {
    const { url, ...restConfig } = config;
    return axios.get(url, restConfig);
  }

  static async post(config) {
    const { url, data, header, ...restConfig } = config;
    let bodyData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      secret
    ).toString();

    if (size(header) !== 0) {
      axios.defaults.headers.common["Authorization"] = header;
    }

    let req_data = { input_data: bodyData };
    let resp_data = await axios.post(url, req_data, restConfig);
    let bytes = CryptoJS.AES.decrypt(resp_data.data.output_data, secret);
    resp_data.data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return resp_data;
  }

  // static async masterPost(config) {
  //   const { url, data, ...restConfig } = config;
  //   let bodyData = CryptoJS.AES.encrypt(
  //     JSON.stringify(data),
  //     secret
  //   ).toString();
  //   let req_data = { input_data: bodyData };
  //   let resp_data = await masterAxios.post(url, req_data, restConfig);
  //   let bytes = CryptoJS.AES.decrypt(resp_data.data.output_data, secret);
  //   resp_data.data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  //   return resp_data;
  // }

  static async post2(config) {
    const { url, data, ...restConfig } = config;
    let resp_data = await axios.post(url, data, restConfig);
    return resp_data;
  }
}
