import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  userToken: "",
  user_id: null,
  user_name: "",
  label_name: "",
  sysAdmin: false,
  drawerOpen: false,
  partyFilterList: [],
  openAlert: false,
  alertNo: 0,
  alertData: [],
  activeCategory: [],
  partyId: null,

};

export const appStateSlice = createSlice({
  name: "appStateReducer",
  initialState,
  reducers: {
    resetAppState: (state, action) => {
      return {
        ...initialState,
      };
    },

    changeLoggedInStatus: (state, action) => {
      state.isLoggedIn = action.payload.isLoggedIn;
      state.userToken = action.payload.userToken;
      state.onlyDeliveryPartner = action.payload.onlyDeliveryPartner;
      state.user_id = action.payload.user_id;
      state.user_name = action.payload.user_name;
      state.sysAdmin = action.payload.sysAdmin;
    },
    
  },
});

export const {
  resetAppState,
  setPartyId,
  changeLoggedInStatus,
  setOpenDrawer,
  setOpenAlert,
  setLabelName,
  setAlertNo,
  setAlertData,
 } = appStateSlice.actions;

export default appStateSlice.reducer;
