import React, { useCallback, useEffect, useState } from "react";
import Banner from "../assets/images/franchise/franchise_bg_mid.png";
import BannerMobile from "../assets/images/franchise/back_mid_mobile.png";
import GreenLines from "../assets/images/franchise/green_lines.png";
import Pizza from "../assets/images/franchise/pizza_1.png";
import Icon1 from "../assets/images/franchise/big_icon_1.png";
import Icon2 from "../assets/images/franchise/big_icon_2.png";
import Icon3 from "../assets/images/franchise/big_icon_3.png";
import Icon4 from "../assets/images/franchise/big_icon_4.png";
import Icon5 from "../assets/images/franchise/big_icon_5.png";
import Icon6 from "../assets/images/franchise/big_icon_6.png";
import Icon7 from "../assets/images/franchise/big_icon_7.png";
import Icon8 from "../assets/images/franchise/big_icon_8.png";
import Icon9 from "../assets/images/franchise/big_icon_9.png";
import Icon10 from "../assets/images/franchise/big_icon_10.png";
import Tomato from "../assets/images/franchise/tomato_1.png";
import Leaf1 from "../assets/images/franchise/leaf_1.png";
import Leaf2 from "../assets/images/home/leaf_2.png";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { joiResolver } from "@hookform/resolvers/joi";
import useRestaurantCategory from "../data/talons/RestaurantCategory/useRestaurantCategory";
import { resetInquiryFormData } from "../data/reducers/restaurantCategory.reducer";

const InquiryAndServices = () => {
  const dispatch = useDispatch();
  const { InquiryEntry, inquiryEntrySchema, setAlert, alert } =
    useRestaurantCategory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    setValue,
  } = useForm({
    resolver: joiResolver(inquiryEntrySchema),
    defaultValues: {
      name: "",
      contact_num: "",
      email_id: "",
      city: "",
    },
  });
  useEffect(() => {
    const timer = setTimeout(() => {
      setAlert(false);
    }, 3000);

    // To clear or cancel a timer, you call the clearTimeout(); method,
    // passing in the timer object that you created into clearTimeout().

    return () => clearTimeout(timer);
  }, [alert]);
  const onUserSubmit = useCallback(async () => {
    try {
      const res = await InquiryEntry({
        ...getValues(),
      });
      if (res == true) {
        setAlert(true);
        reset();
        dispatch(resetInquiryFormData());
        setValue("");
      }
    } catch (err) {
      console.log("errr", err);
    }
  }, [InquiryEntry]);

  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");

    if (e.target.value.toString().length === 0) return;
    return (e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 10));
  };
  return (
    <section className="relative flex lg:px-20 xl:px-36 -mt-12 z-10">
      <img
        src={Banner}
        alt="Background Image"
        className="absolute w-full h-full top-0 left-0 -z-10 hidden lg:flex"
      />
      <img
        src={BannerMobile}
        alt="Background Image"
        className="absolute w-full h-full top-0 left-0 -z-10 flex lg:hidden object-left-top object-cover"
      />
      <img
        src={Tomato}
        alt="Tomato"
        className="absolute object-contain w-24 xl:w-32 right-24 xl:right-48 top-96 xl:top-1/4 hidden lg:flex"
      />
      <img
        src={Leaf1}
        alt="Leaf"
        className="absolute object-contain w-32 left-32 xl:left-60 top-1/4 xl:top-1/3 hidden lg:flex"
      />
      <img
        src={Leaf2}
        alt="Leaf"
        className="absolute object-contain w-40 -bottom-8 right-44 hidden lg:flex"
      />
      <div className="relative flex flex-col">
        <div className="flex flex-col w-full -mt-36">
          <h2 className="bg-yellow text-center self-center rounded-t-2xl md:rounded-t-3xl pt-2 md:pt-4 px-8 md:px-20 text-dark-green font-title text-2xl md:text-4xl">
            INQUIRY FORM
          </h2>
          <form className="bg-yellow flex flex-col py-12 px-12 md:px-20 rounded-2xl md:rounded-3xl gap-8 w-full -mt-1">
            <div className="flex gap-8 w-full flex-wrap md:flex-nowrap">
              <input
                type="text"
                placeholder="First Name"
                className="w-full py-2 px-4 rounded-lg"
                {...register("name")}
                autoComplete="false"
              />
              <input
                type="email"
                placeholder="Your E-mail ID"
                className="w-full py-2 px-4 rounded-lg"
                {...register("email_id")}
                autoComplete="false"
              />
            </div>
            <div className="flex gap-8 w-full flex-wrap md:flex-nowrap">
              <input
                type="tel"
                onInput={(e) => onlyNumbers(e)}
                placeholder="Mobile Number"
                {...register("contact_num")}
                autoComplete="false"
                className="w-full py-2 px-4 rounded-lg"
              />
              <input
                type="text"
                placeholder="Your City"
                className="w-full py-2 px-4 rounded-lg"
                {...register("city")}
                autoComplete="false"
              />
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                handleSubmit(onUserSubmit());
              }}
              className="font-description-bold text-white bg-dark-green py-2 px-4 md:px-12 rounded-lg self-center text-base md:text-xl uppercase"
            >
              Submit Form
            </button>
            {alert === true ? (
              <div className="text-center ease-in text-white">
                Thank You For Showing Interest!
              </div>
            ) : null}
            {/* <div className="relative flex justify-center items-center md:pt-8">
              <img
                src={GreenLines}
                alt="Divider"
                className="absolute object-cover md:object-contain w-full xl:w-1/2 h-14 md:h-auto"
              />
              <p className="text-green font-description-light text-sm md:text-xl lg:text-2xl text-center max-w-52 md:max-w-full">
                FOR MORE INFORMATION, CALL{" "}
                <span className="font-description-bold">+91 99048 84433</span>
              </p>
            </div> */}
          </form>
          <img src={Pizza} alt="Pizza" />
        </div>
        <div className="flex flex-col gap-8 justify-center items-center pt-12 pb-36">
          <h3 className="font-title text-green text-3xl md:text-4xl lg:text-5xl text-center">
            Supportive services
          </h3>
          <div className="flex flex-wrap gap-2 md:gap-12 justify-center items-center max-w-lg lg:max-w-5xl">
            <div className="flex flex-col justify-center items-center gap-4 w-32 md:w-48">
              <img
                src={Icon1}
                alt="Icon 1"
                className="object-contain h-20 md:h-32"
              />
              <span className="text-center font-description-semibold text-sm md:text-base">
                POS Billlng & <br /> Separate Application <br /> Panel
              </span>
            </div>
            <div className="flex flex-col justify-center items-center gap-4 w-32 md:w-48">
              <img
                src={Icon2}
                alt="Icon 2"
                className="object-contain h-20 md:h-32"
              />
              <span className="text-center font-description-semibold text-sm md:text-base">
                SMS and <br /> Whatsapp Marketing <br /> Support
              </span>
            </div>
            <div className="flex flex-col justify-center items-center gap-4 w-32 md:w-48">
              <img
                src={Icon3}
                alt="Icon 3"
                className="object-contain h-20 md:h-32"
              />
              <span className="text-center font-description-semibold text-sm md:text-base">
                Registration <br /> Support with Online <br /> Delivery Channels
              </span>
            </div>
            <div className="flex flex-col justify-center items-center gap-4 w-32 md:w-48">
              <img
                src={Icon4}
                alt="Icon 4"
                className="object-contain h-20 md:h-32"
              />
              <span className="text-center font-description-semibold text-sm md:text-base">
                Raw Material <br /> Setup & Kitchen Staff <br /> Training
                Support
              </span>
            </div>
            <div className="flex flex-col justify-center items-center gap-4 w-32 md:w-48">
              <img
                src={Icon5}
                alt="Icon 5"
                className="object-contain h-20 md:h-32"
              />
              <span className="text-center font-description-semibold text-sm md:text-base">
                Marketing Guidelines <br /> & Sales grow up <br /> Guidelines
              </span>
            </div>
            <div className="flex flex-col justify-center items-center gap-4 w-32 md:w-48">
              <img
                src={Icon6}
                alt="Icon 6"
                className="object-contain h-20 md:h-32"
              />
              <span className="text-center font-description-semibold text-sm md:text-base">
                Regular <br /> visit of company <br /> employees
              </span>
            </div>
            <div className="flex flex-col justify-center items-center gap-4 w-32 md:w-48">
              <img
                src={Icon7}
                alt="Icon 7"
                className="object-contain h-20 md:h-32"
              />
              <span className="text-center font-description-semibold text-sm md:text-base">
                Grand Opening <br /> Standard layout given <br /> by Company
              </span>
            </div>
            <div className="flex flex-col justify-center items-center gap-4 w-32 md:w-48">
              <img
                src={Icon8}
                alt="Icon 8"
                className="object-contain h-20 md:h-32"
              />
              <span className="text-center font-description-semibold text-sm md:text-base">
                Event Planning <br /> Guidelines <br /> Support
              </span>
            </div>
            <div className="flex flex-col justify-center items-center gap-4 w-32 md:w-48">
              <img
                src={Icon9}
                alt="Icon 9"
                className="object-contain h-20 md:h-32"
              />
              <span className="text-center font-description-semibold text-sm md:text-base">
                Regular Food <br /> Innovations
              </span>
            </div>
            <div className="flex flex-col justify-center items-center gap-4 w-32 md:w-48">
              <img
                src={Icon10}
                alt="Icon 10"
                className="object-contain h-20 md:h-32"
              />
              <span className="text-center font-description-semibold text-sm md:text-base">
                Social Media <br /> Support
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InquiryAndServices;
