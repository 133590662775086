import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useApi } from "../apiCall/useApi";
import { Urls } from "../../../utils/constant";
import Joi from "joi";
import {
  setRestaurantCategoryList,
  setRestaurantProductList,
  setBranchMasterList,
  setInquiryList,
  setBranchId
} from "../../reducers/restaurantCategory.reducer";

import { getBranchMasterList,getBranchId } from "../../selectors/restaurantCategory.selector";
import { size } from "lodash";

export const inquiryEntrySchema = Joi.object({
  name: Joi.string().required(),
  contact_number: Joi.string().required(),
});

const menu_list = [
  { id: 0, label: "Our Menu" },
  { id: 1, label: "Veg Menu" },
  { id: 2, label: "Veg & Non Veg" },
];

export default function useRestaurantCategory() {
  const dispatch = useDispatch();
  const { apiPost } = useApi();
  const branchMasterList = useSelector(getBranchMasterList);
  const branchId = useSelector(getBranchId)
  const [loading, setLoading] = useState(false);
  const [menuId, setMenuId] = useState(null);

  // const webUserId = useSelector(getWebUserId);
  const [InquiryId, setinquiryId] = useState(null);
  // const [name, setName] = useState(null);
  // const [first_name, setFirstName] = useState(null);
  // const [last_name, setLastName] = useState(null);
  // const [email_id, setEmail] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [menuURL, setMenuURL] = useState("");
  const [dataText, setDataText] = useState(0);
  const [count, setCount] = useState(0);
  const [alert, setAlert] = useState(false);

  const InquiryEntry = useCallback(
    async ({ name, contact_num, city, email_id }) => {
      if (loading) return;
      setLoading(true);
      try {
        //showLoader();

        const { data } = await apiPost({
          url: Urls.inquiryAdd(),
          data: {
            name: name,
            email_id: email_id,
            contact_num: contact_num,
            city: city,
          },
        });
        dispatch(setInquiryList(data.id));
        let id = data.id;
        if (size(id) === 36) {
          return true;
        }
      } catch (err) {
        // hideLoader();
        setLoading(false);
        console.log(err);
        return false;
      }
    },
    [loading]
  );

  const ContactUsInquiryEntry = useCallback(
    async ({ name, contact_num, message, email_id }) => {
      if (loading) return;
      setLoading(true);
      try {
        //showLoader();

        const { data } = await apiPost({
          url: Urls.inquiryAdd(),
          data: {
            name: name,
            email_id: email_id,
            contact_num: contact_num,
            message: message,
          },
        });
        dispatch(setInquiryList(data.id));
        let id = data.id;
        if (size(id) === 36) {
          return true;
        }
      } catch (err) {
        // hideLoader();
        setLoading(false);
        console.log(err);
        return false;
      }
    },
    [loading]
  );

  const fetchRestaurantProductList = useCallback(
    async ({ categoryId, branchId }) => {
      try {
        const { data } = await apiPost({
          url: Urls.getRestaurantProduct(),
          data: {
            category_id: categoryId,
            branch_id: branchId,
            page_size: 12,
            current_page: 1,
          },
        });
        dispatch(setRestaurantProductList(data.data));
      } catch (err) {
        console.log(err);
      }
    },
    []
  );

  const fetchRestaurantCategoryList = useCallback(
    async ({ branchId }) => {
      try {
        const { data } = await apiPost({
          url: Urls.getRestaurantCategoryCombo(),
          data: { branch_id: branchId },
        });

        // setting list to reducer
        if (size(categoryId) <= 0) {
          setCategoryId(size(data) > 0 ? data.data[0].id : "");

          if (size(data) > 0) {
            await fetchRestaurantProductList({
              categoryId: data.data[0].id,
              branchId: branchId,
            });
          }
        }

        dispatch(setRestaurantCategoryList(data.data));
      } catch (err) {
        console.log(err);
      }
    },
    [categoryId, fetchRestaurantProductList]
  );

  const fetchBranchMasterList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getBranchMaster(),
        data: {},
      });
      dispatch(setBranchMasterList(data.data));
      // setting list to reducer
      if (size(branchId) <= 0) {
        dispatch(setBranchId(size(data) > 0 ? data.data[0].id : ""));
        setMenuURL(size(data) > 0 ? data.data[0].document_path : "");
        await fetchRestaurantCategoryList({
          branchId: size(data) > 0 ? data.data[0].id : "",
        });
      }
    } catch (err) {
      console.log(err);
    }
  }, [branchId, fetchRestaurantCategoryList]);

  const InquiryEntryHome = useCallback(
    async ({ first_name, last_name, contact_num, email_id }) => {
      if (loading) return;
      setLoading(true);
      try {
        //showLoader();
        const { data } = await apiPost({
          url: Urls.inquiryAdd(),
          data: {
            name: first_name + last_name,
            email_id: email_id,
            contact_num: contact_num,
          },
        });
        dispatch(setInquiryList(data.id));
        let id = data.id;
        if (size(id) === 36) {
          return true;
        }
        setLoading(false);
        // hideLoader();
      } catch (err) {
        // hideLoader();
        setLoading(false);
        console.log(err);
        return false;
      }
    },
    [loading]
  );

  const handleRestaurantCategoryNameChange = useCallback(
    async (e) => {
      setCategoryId(e.target.value);
      await fetchRestaurantProductList({
        categoryId: e.target.value,
        branchId: branchId,
      });
    },
    [branchId, fetchRestaurantProductList]
  );

  const handleBranchMasterComboChange = useCallback(
    async (e) => {
      dispatch(setBranchId(e.target.value));
      const object = branchMasterList.find((obj) => obj.id === e.target.value);
      setMenuURL(object.document_path);
      await fetchRestaurantCategoryList({ branchId: e.target.value });

      // if (size(categoryId) >= 0) {
      //   await fetchRestaurantProductList({ categoryId: categoryId, branchId: e.target.value });
      // }
    },
    [branchMasterList]
  );

  const handleLeftClick = () => {
    if (count === 0) {
      let max = branchMasterList.length - 1;
      setCount(max);
      setDataText(max);
    } else if (count > 0) {
      setCount((count) => count - 1);
      setDataText((count) => count - 1);
    }
  };

  const handleRightClick = () => {
    if (count === branchMasterList.length - 1) {
      setCount(0);
      setDataText(0);
    } else {
      setCount((count) => count + 1);
      setDataText((count) => count + 1);
    }
  };

  const handleMenuChange = useCallback(
    (e) => {
      setMenuId(e.target.value);
    },
    [menuId]
  );

  const formLoadEvent = useCallback(async (e) => {
    await fetchBranchMasterList();
  }, []);

  return {
    formLoadEvent,
    InquiryEntry,
    InquiryEntryHome,
    fetchRestaurantCategoryList,
    fetchRestaurantProductList,
    handleRestaurantCategoryNameChange,
    handleBranchMasterComboChange,
    fetchBranchMasterList,
    handleLeftClick,
    handleRightClick,
    ContactUsInquiryEntry,
    setAlert,
    handleMenuChange,
    setRestaurantCategoryList,
    categoryId,
    count,
    dataText,
    alert,
    menu_list,
    menuId,
    menuURL,
  };
}
