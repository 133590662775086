import { combineReducers, createStore } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import appStateReducer from "./data/reducers/appState.reducer";
import restaurantCategoryReducer from "./data/reducers/restaurantCategory.reducer";

const rootReducer = combineReducers({
  appstate: appStateReducer,
  restaurantCategory: restaurantCategoryReducer,
});

const persistConfig = {
  key: "crm-admin",

  storage,
  whitelist: ["appState"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, composeWithDevTools());

export const persistor = persistStore(store);

export default store;
