import React, { useEffect, useState } from "react";
import Banner from "../assets/videos/franchise_banner.mp4";
import Pause from "../assets/images/franchise/pause.png";
import Play from "../assets/images/franchise/play.png";

const FranchiseBanner = () => {
  const [videoPlay, setVideoPlay] = useState(false);
  useEffect(() => {
    let banner_video = document.getElementById("video");
    if (banner_video.pause()) {
      setVideoPlay(false);
    }
  }, [setVideoPlay]);
  return (
    <section className="flex w-full relative justify-center items-center -mb-12">
      {!videoPlay && (
        <button
          className="absolute z-10"
          onClick={() => {
            let banner_video = document.getElementById("video");
            banner_video.play();
            setVideoPlay(true);
          }}
        >
          <img src={Play} alt="play button" />
        </button>
      )}
      {videoPlay && (
        <button
          className="absolute z-10"
          onClick={() => {
            let banner_video = document.getElementById("video");
            banner_video.pause();
            setVideoPlay(false);
          }}
        >
          <img src={Pause} alt="play button" />
        </button>
      )}
      <video
        className="rounded-b-3xl md:rounded-b-[3rem] w-full"
        src={Banner}
        id="video"
      />
    </section>
  );
};

export default FranchiseBanner;
