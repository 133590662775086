import React, { useEffect } from "react";
import CarousalImage from "../assets/images/home/Image_1.png";
import Leaf from "../assets/images/home/leaf_2.png";
import Element from "../assets/images/home/element_1.png";
import { useSelector } from "react-redux";
import { getBranchMasterList } from "../data/selectors/restaurantCategory.selector";
import VerticalsCarousel from "../components/VerticalsCarousel";
import Icon1 from "../assets/images/home/icon_2.png";
import Icon2 from "../assets/images/home/icon_3.png";
import Icon3 from "../assets/images/home/icon_4.png";
import Icon4 from "../assets/images/home/icon_5.png";
import useRestaurantCategory from "../data/talons/RestaurantCategory/useRestaurantCategory";

const Verticals = () => {
  const { fetchBranchMasterList } = useRestaurantCategory();
  const branchMasterList = useSelector(getBranchMasterList);

  useEffect(() => {
    fetchBranchMasterList();
  }, []);


  return (
    <section className="relative flex flex-col bg-white px-4 md:px-8 lg:px-20 xl:px-36 py-12 gap-4 w-full overflow-x-hidden">
      <h1 className="text-yellow font-title text-5xl lg:text-6xl text-center">
        OUR VERTICALS
      </h1>
      <p className="text-center font-description-medium text-lg max-w-6xl self-center">
        Currently we are at 12 locations, with eager and ambitious plans to roll
        out different company owned and franchised outlets at key locations both
        in Gujarat as well as India.
      </p>
      <div className="flex gap-8 flex-col lg:flex-row">
        <div className="relative flex flex-1">
          <img
            src={Leaf}
            alt="Leaf"
            className="absolute hidden lg:flex w-32 object-contain left-0 top-1/3"
          />
          <img
            src={CarousalImage}
            alt="Image"
            className="object-contain w-full lg:w-96 2xl:w-[30rem] lg:ml-16 xl:ml-32 2xl:ml-48"
          />
        </div>
        <VerticalsCarousel data={branchMasterList} />
        <img
          src={Element}
          alt="Element"
          className="absolute hidden lg:flex w-44 object-contain right-0 bottom-1/4 xl:bottom-1/3"
        />
      </div>
      <div className="flex gap-2 md:gap-8 xl:gap-20 justify-center items-center">
        <div className="flex flex-col gap-2 md:gap-4 justify-center items-center">
          <img
            src={Icon1}
            alt="Outlets pan India"
            className="object-contain h-6 md:h-8"
          />
          <span className="font-title text-yellow text-2xl md:text-5xl xl:text-6xl">
            12
          </span>
          <span className="font-description-medium uppercase text-green text-xs md:text-xl xl:text-2xl text-center">
            OUTLETS PAN <br /> INDIA
          </span>
        </div>
        <div className="flex flex-col gap-2 md:gap-4 justify-center items-center">
          <img
            src={Icon2}
            alt="Happy customers"
            className="object-contain h-6 md:h-8"
          />
          <span className="font-title text-yellow text-2xl md:text-5xl xl:text-6xl">
            500k+
          </span>
          <span className="font-description-medium uppercase text-green text-xs md:text-xl xl:text-2xl text-center">
            HAPPY <br /> CUSTOMERS
          </span>
        </div>
        <div className="flex flex-col gap-2 md:gap-4 justify-center items-center">
          <img
            src={Icon3}
            alt="Delivery Orders"
            className="object-contain h-6 md:h-8"
          />
          <span className="font-title text-yellow text-2xl md:text-5xl xl:text-6xl">
            300K
          </span>
          <span className="font-description-medium uppercase text-green text-xs md:text-xl xl:text-2xl text-center">
            Delivery <br /> Orders
          </span>
        </div>
        <div className="flex flex-col gap-2 md:gap-4 justify-center items-center">
          <img
            src={Icon4}
            alt="Profitable Outlets"
            className="object-contain h-6 md:h-8"
          />
          <span className="font-title text-yellow text-2xl md:text-5xl xl:text-6xl">
            100%
          </span>
          <span className="font-description-medium uppercase text-green text-xs md:text-xl xl:text-2xl text-center">
            PROFITABLE <br /> OUTLETS
          </span>
        </div>
      </div>
    </section>
  );
};

export default Verticals;
