import React, { useCallback, useEffect } from "react";
import Name from "../assets/images/contact/ContactForm1.png";
import Call from "../assets/images/contact/ContactForm2.png";
import Mail from "../assets/images/contact/ContactForm3.png";
import Phone from "../assets/images/contact/ContactNumber.png";
import MailBlack from "../assets/images/contact/ContactEmail.png";
import Address from "../assets/images/contact/ContactLocation.png";
import Insta from "../assets/images/contact/ContactSocialInsta.png";
import Facebook from "../assets/images/contact/ContactSocialFB.png";
import { useDispatch } from "react-redux";
import { joiResolver } from "@hookform/resolvers/joi";
import { resetInquiryFormData } from "../data/reducers/restaurantCategory.reducer";
import useRestaurantCategory from "../data/talons/RestaurantCategory/useRestaurantCategory";
import { useForm } from "react-hook-form";

const ContactUsContainer = () => {
  const { ContactUsInquiryEntry, inquiryEntrySchema, alert, setAlert } =
    useRestaurantCategory();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    setValue,
  } = useForm({
    resolver: joiResolver(inquiryEntrySchema),
    defaultValues: {
      name: "",
      contact_num: "",
      email_id: "",
      message: "",
    },
  });

  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");

    if (e.target.value.toString().length === 0) return;
    return (e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 10));
  };

  const onUserSubmit = useCallback(async () => {
    try {
      const res = await ContactUsInquiryEntry({
        ...getValues(),
      });
      if (res == true) {
        setAlert(true);
        reset();
        dispatch(resetInquiryFormData());
        setValue("");
      }
    } catch (err) {
      console.log("errr", err);
    }
  }, [ContactUsInquiryEntry]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlert(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [alert]);

  return (
    <section className="relative flex flex-col lg:flex-row min-h-screen h-full bg-cover bg-ContactBG -mt-8 pt-24 md:pt-44 pb-24 md:px-8 lg:px-20 xl:px-36 gap-8">
      <div className="relative w-full flex-1 self-center flex flex-col gap-8 px-4 md:px-0">
        <h2 className="font-title text-yellow text-4xl lg:text-6xl">
          Let's connect
        </h2>
        <p className="text-white md:font-description-semibold max-w-xs md:max-w-xl xl:max-w-lg">
          What you feel about us matters a lot! Please share your experience of
          The Fusion Pizza with us. Your feedback or suggestions for improvement
          are highly appreciated.
        </p>
        <div className="flex gap-4 flex-col px-8 md:max-w-xl xl:max-w-lg">
          <div className="flex gap-4">
            <img
              src={Phone}
              alt="Phone Number"
              className="object-contain w-4"
            />
            <span className="text-white md:font-description-semibold">
              <p>
                <a href="tel:+919904884433">+91 99048 84433</a>
              </p>
            </span>
          </div>
          <a
            className="flex gap-4"
            href="mailto:info@thefusionpizza.in"
            target="_blank"
          >
            <img src={MailBlack} alt="Mail ID" className="object-contain w-4" />
            <span className="text-white md:font-description-semibold">
              info@thefusionpizza.in
            </span>
          </a>
          <a
            className="flex gap-4"
            href="https://maps.app.goo.gl/oLJeW1gComo6MNMP7"
            target="_blank"
          >
            <img
              src={Address}
              alt="Address"
              className="object-contain w-4 self-start mt-1"
            />
            <span className="text-white md:font-description-semibold">
              Shop No - 12, Ground floor, Town plaza, <br />
              Near Raspan cross road, Nikol, Ahmedabad
            </span>
          </a>
        </div>
        <div className="flex flex-col gap-4">
          <h3 className="font-title text-yellow text-3xl lg:text-5xl">
            Get Social with us
          </h3>
          <div className="flex gap-4">
            <a
              href="https://www.instagram.com/thefusionpizza_india/?hl=en"
              target="_blank"
            >
              <img
                src={Insta}
                alt="Instagram"
                className="object-contain h-12"
              />
            </a>
            <a href="https://www.facebook.com/thefusionpizza/" target="_blank">
              <img
                src={Facebook}
                alt="Facebook"
                className="object-contain h-12"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="relative flex flex-col w-full flex-1">
        <span className="text-white md:font-description-semibold text-base text-right md:pb-2 pr-2 md:pr-0">
          For general and business inquiries
        </span>

        <form className="flex flex-col gap-8 w-full px-8 md:px-12 xl:px-16 pt-8 md:pt-12 xl:pt-16 pb-20 bg-yellow md:rounded-3xl">
          <div className="flex flex-col gap-2">
            <label className="text-black font-description-semibold">
              Your Name
            </label>
            <div className="bg-white p-2 md:p-4 flex w-full gap-4">
              <img
                src={Name}
                alt="Name"
                className="object-contain w-4 md:w-6"
              />
              <input
                type="text"
                className="w-full bg-transparent focus:outline-none"
                {...register("name")}
                autoComplete="false"
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <label className="text-black font-description-semibold">Mail</label>
            <div className="bg-white p-2 md:p-4 flex w-full gap-4">
              <img
                src={Mail}
                alt="Mail"
                className="object-contain w-6 md:w-8"
              />
              <input
                type="email"
                className="w-full bg-transparent focus:outline-none"
                {...register("email_id")}
                autoComplete="false"
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <label className="text-black font-description-semibold">
              Phone
            </label>
            <div className="bg-white p-2 md:p-4 flex w-full gap-4">
              <img
                src={Call}
                alt="Call"
                className="object-contain w-6 md:w-8"
              />
              <input
                type="tel"
                className="w-full bg-transparent focus:outline-none"
                {...register("contact_num")}
                autoComplete="false"
                onInput={(e) => onlyNumbers(e)}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <label className="text-black font-description-semibold">
              Message
            </label>
            <div className="flex w-full gap-4">
              <textarea
                cols="30"
                rows="10"
                placeholder="Type your Message"
                className="p-4 w-full focus:outline-none"
                {...register("message")}
                autoComplete="false"
              />
            </div>
          </div>
          {alert === true ? (
            <div className="ease-in text-center">
              Thank You For Showing Interest!
            </div>
          ) : null}
          <button
            className="bg-green text-yellow px-8 md:px-12 py-4 text-sm md:text-base rounded-xl self-center absolute -bottom-6 z-10"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit(onUserSubmit());
            }}
          >
            Send Message
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactUsContainer;
